import { render, staticRenderFns } from "./JoinToModuleComponent.vue?vue&type=template&id=3c5a3fcb&scoped=true"
import script from "./JoinToModuleComponent.vue?vue&type=script&lang=js"
export * from "./JoinToModuleComponent.vue?vue&type=script&lang=js"
import style0 from "./JoinToModuleComponent.vue?vue&type=style&index=0&id=3c5a3fcb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5a3fcb",
  null
  
)

export default component.exports